import React from "react";

import { Layout } from "~/components";
import { VacationsAndTourisms } from "~/views";

const HeliVacationsAndTourisms: React.FunctionComponent = () => (
  <Layout title="Heli-Vacations & Heli-Tourism">
    <VacationsAndTourisms />
  </Layout>
);

export default HeliVacationsAndTourisms;
